import DOMPurify from 'dompurify';

/**
 * Recursively sanitizes an object, array, or string.
 * @param {any} data - The data to sanitize.
 * @returns {any} - Sanitized data.
 */
export function sanitizePayload(data) {
  if (Array.isArray(data)) {
    return data.map(item => sanitizePayload(item));
  } else if (typeof data === 'object' && data !== null) {
    const sanitizedObject = {};
    for (const [key, value] of Object.entries(data)) {
      sanitizedObject[key] = sanitizePayload(value);
    }
    return sanitizedObject;
  } else if (typeof data === 'string') {
    return DOMPurify.sanitize(data);
  }
  return data;
}
