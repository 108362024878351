import { BehaviorSubject } from 'rxjs';
import { httpRequest } from '@/functions/httpRequest';

const defaultOptions = {
  version: '2',
  area: 'ecisync',
};

export const mailState = new BehaviorSubject({
  data: [],
  details: {}
});
export const mailThreadsState = new BehaviorSubject({
  data: [],
  details: {}
});

export async function getAccountInfo() {
  try {
    const details = await httpRequest('get', 'nylas/account', {}, {
      area: 'ecisync',
      throwErrors: false,
    });
    mailState.next({
      data: [],
      details
    });
  } catch(error) {
    console.error(error);
    mailState.next({
      data: [],
      details: {}
    });
  }
}

export async function getMail(params = {
  limit: '1',
  offset: '0',
  mode: 'compact'
}) {
  const searchParams = new URLSearchParams(params);
  try {
    const resp = await httpRequest(
      'get',
      `messages?${searchParams.toString()}`,
      {},
      { 
        version: '2', 
        area: 'ecisync',
        throwErrors: false,
      }
    );
    const existingState = mailState.getValue();
    mailState.next({
      ...existingState,
      data: resp.data
    });
    return resp.data;
  } catch(error) {
    console.error(error);
  }
}

export async function sendMail(payload) {
  try {
    await httpRequest(
      'post',
      'messages',
      payload,
      { version: '2', area: 'ecisync', }
    );
    return true;
  } catch(error) {
    console.error(error);
    return false;
  }
}


export async function getThreadsSummary({ contact_ids = null } = {}) {
  if (!contact_ids?.length) return null;
  const searchParams = new URLSearchParams({ contact_ids });
  try {
    const resp = await httpRequest(
      'get',
      `threads/contactsummary?${searchParams.toString()}`,
      { }, defaultOptions
    );
    return resp.contact_thread_summary;
  } catch(error) {
    console.error(error);
    return null;
  }
}

export async function getThreads(params = {
  any_email: undefined,
  page_token: undefined,
  limit: '5'
}) {
  const searchParams = new URLSearchParams(params);
  try {
    const resp = await httpRequest(
      'get',
      `threads?${searchParams.toString()}`,
      {}, defaultOptions
    );
    const existingState = mailThreadsState.getValue();
    mailThreadsState.next({
      ...existingState,
      data: resp.data
    });
    return resp.data;
  } catch(error) {
    console.error(error);
    return null;
  }
}

export async function getThread(params = {
  thread_id: '',
  expand_images: 'false',
  view_html: 'false',
}) {
  try {
    const { thread_id, expand_images = 'false', view_html = 'false' } = params;
    if (!thread_id) throw new Error('No thread id provided');
    const searchParams = new URLSearchParams({ expand_images, view_html });
    const resp = await httpRequest(
      'get',
      `threads/${thread_id}?${searchParams.toString()}`,
      {}, defaultOptions
    );
    return resp;
  } catch(error) {
    console.error(error);
    return null;
  }
}

export async function getMailAttachment(params = { message_id: '', attachment_id: ''}) {
  if (!params?.message_id || !params?.attachment_id) throw new Error('No message_id or attachment_id provided') ;
  const searchParams = new URLSearchParams(params);
  return httpRequest('get', `attachments?${searchParams}`, {}, { ...defaultOptions, throwErrors: false });
}