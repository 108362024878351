import { loadTranslationsForLocale } from './../functions/translate';
import { BehaviorSubject } from 'rxjs';
import { TranslationsSubject, Locale } from '@/types/translationsSubjectTypes';
import { httpRequest } from '@/functions/httpRequest';
import { BASE_LOCALE } from '@/functions/translate';

export const translationsState = new BehaviorSubject<TranslationsSubject>({
  locale: BASE_LOCALE,
  locales: [],
});

export const getCalendarLocale = () => {
  const locale = translationsState.getValue().locale;
  if (locale === 'pt-BR') return 'pt-br';
  if (locale === 'zh-HK') return 'zh-hk';
  if (locale.startsWith('zh')) return 'zh-cn';
  return locale.split('-')[0];
};

export async function getSubjectLanguages() {
  try {
    const locales = await httpRequest('get', 'tenants/current/languages/supported', {}, { area: 'iam' });
    const locale = locales.find(l => l.default)?.locale || BASE_LOCALE;
    translationsState.next({
      ...translationsState.getValue(),
      locales,
      locale,
    });
    await loadTranslationsForLocale(locale);
    return locales;
  } catch (error) {
    console.error(error);
  }
}

export function getSubjectDefaultLanguage() {
  return translationsState.getValue().locales.find((l) => l.default);
}

export async function putSubjectDefaultLanguage(id: string, locale: Locale) {
  await httpRequest('put', `subjects/${id}/languages/default`, locale, { area: 'iam', });
  await loadTranslationsForLocale(locale.locale || BASE_LOCALE);
  translationsState.next({
    ...translationsState.getValue(),
    locale: locale.locale || BASE_LOCALE,
  });
}

export async function getTenantLanguages() {
  return await httpRequest('get', 'tenants/current/languages', {}, { area: 'iam' });
}

// Here array of locales or array from getTenantLanguages should be passed
export async function putTenantLanguages(languageArray: string[]) {
  return httpRequest('put', 'tenants/current/languages/supported', languageArray, { area: 'iam', });
}
