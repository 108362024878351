// <!-- todo: translate -->
import { httpRequestRaw } from './httpRequestRaw';
import { StatusCodes } from 'http-status-codes';
import { HttpRequestOptions, Error } from '@/types/requestTypes';
import { setErrors } from './setErrors';
import hasOwnProperty from './hasOwnProperty';
import { redirectLogin } from './redirectLogin';
import { tGlobal as t } from './translate';
import { sanitizePayload } from './sanitizeUtil';

const { NOT_FOUND, UNAUTHORIZED, } = StatusCodes;

const defaultOptions = {
  version: '2',
  area: 'crm',
  throwErrors: true,
  debug: false,
  sanitize: true,
};

export const httpRequest = async (method = 'get', endpoint, payload = {}, options: HttpRequestOptions = defaultOptions) => {
  options = {
    ...defaultOptions,
    ...options
  };
  try {
    // eslint-disable-next-line
    if (options.debug) console.log('httpRequest, request: ', {method, endpoint, payload, options});

    let sanitizedPayload = payload;
    // Check if sanitization is enabled
    if (options.sanitize) {
      sanitizedPayload = sanitizePayload(payload);
    } else {
      // eslint-disable-next-line no-console
      console.log('Sanitization Skipped for request: ', 'from endpoint: ', endpoint, 'with options: ', options);
    }
    // Make the request with the processed payload
    const response = await httpRequestRaw(method, endpoint, sanitizedPayload, options);
    // eslint-disable-next-line
    if (options.debug) console.log('httpRequest, response: ', response);
    return response;
  } catch (error) {
    const errObj = {
      status: NOT_FOUND,
      errors: [] as Error[]
    };
    try {
      let errorData = null;
      if (hasOwnProperty(error, 'response') && hasOwnProperty(error.response, 'status')) {
        const { status, data } = error.response;
        if (status === UNAUTHORIZED) {
          redirectLogin();
          throw ({ status: 401 });
        }
        
        errObj.status = status;
        errObj.errors = data;
        errorData = data;
      }
      if (options.throwErrors) {
        let errors = {};
        if (Array.isArray(errObj.errors) && errObj.errors[0]) {
          errors = errObj;
        } else if (typeof errObj.errors === 'object') {
          errObj.errors = [{
            title: t('unexpected_error'),
            message: errorData.message ?? errorData.error ?? 'Whoops',
            trace_id: errorData.trace_id ?? errorData.code ?? '',
          }];
          errors = errObj;
        } else {
          errors = {
            errors: [{
              title: t('unexpected_error'),
              message: errObj.errors,
            }]
          };
        }
        switch (errObj.status) {
        case NOT_FOUND:
          setErrors(errObj);
          break;
        default:
          setErrors(errors);
          break;
        }
      }
    } catch (error) {
      if (error.status !== UNAUTHORIZED) {
        setErrors({ errors: [{
          title: t('unexpected_error'),
          message: error,
        }] });
      }
    }
    throw errObj;
  }
};
