import { tUtils as t } from './translate';
import { translationsState } from '@/subjects/translations';
import { BASE_LOCALE } from '@/functions/translate';
/**
 * Functions to work with date and time
 * @author: diakovlev
 */
import { DateTime, Info } from 'luxon';

export const formats = {
  short: DateTime.DATE_SHORT,
  shortwtime: DateTime.DATETIME_SHORT,
  medium: 'DDDD', // 'Do MMMM YYYY',
  long: DateTime.DATE_HUGE, //'dddd Do of MMMM YYYY'
  timeSimple: DateTime.TIME_SIMPLE, // 1:30 PM or 13:30 (depending on locale),
  report: 'cccc d LLL yyyy', // 'Mon 1 Jan 2021'
};

/**
 * @function formatDate
 * Formats date
 * See formats: https://moment.github.io/luxon/#/formatting
 *
 * @param {date|string} date Date string or object
 * @param {string} [format = DD/MM/YYYY] Output format
 * @param {string} [inputFormat = ISO] Input format
 * @return {string} date formatted @format format
 */
export function formatDate(date, format = 'short', inputFormat?, locale?) {
  if (!date) return '';
  const resultingFormat = formats?.[format || 'short'] || format;
  const dt = getDateTime(date, inputFormat);
  if (!dt) {
    console.error('Invalid or empty date:', date, dt);
    return '';
  }
  if (typeof resultingFormat === 'string') {
    return dt.setLocale(locale || translationsState.getValue()?.locale || BASE_LOCALE)
      .toFormat(resultingFormat).replace('Invalid DateTime', '');
  } else {
    return dt.setLocale(locale || translationsState.getValue()?.locale || BASE_LOCALE)
      .toLocaleString(resultingFormat).replace('Invalid DateTime', '');
  }
}

export function getDateTime(value, inputFormat?) {
  if (!value) return undefined;
  if (DateTime.isDateTime(value)) return value;
  if (typeof value === 'string') {
    if (inputFormat) return DateTime.fromFormat(value, inputFormat);
    else return DateTime.fromISO(value);
  }
  else return DateTime.fromJSDate(value);
}

export function formatDateAsISO(date) {
  if (!date) return date;
  return getDateTime(date).toISO();
}

function getDifferenceHoursMins(pastDate, futureDate) {
  const difference = futureDate - pastDate;
  const hours = Math.floor(difference / 60 / 60 / 1000);
  const mins = Math.floor(difference / 60 / 1000);
  return { difference, hours, mins };
}

export function getContractedTime(pastDate, futureDate = new Date()) {
  if (pastDate) {
    const { difference, hours, mins } = getDifferenceHoursMins(pastDate, futureDate);
    const under10Mins = (60 * 1000) * 10;
    if (difference <= under10Mins) {
      return t('just_now');
    }
    if (hours < 24 && hours >= 1) {
      return t('plural_hours_ago', { number: hours });
    }
    if (mins < 60 && mins >= 10) {
      return t('plural_mins_ago', { number: mins });
    }
    return getFullTime(pastDate, false);
  }
  return '';
}

export function getFullTime(date, includeTime = true) {
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = addZero(date.getHours());
  const mins = addZero(date.getMinutes());

  return includeTime ?
    `${day}/${month}/${year} ${hours}:${mins}` : `${day}/${month}/${year}`;
}

export function addZero(num) {
  return num.toString().length === 1 ? `0${num}` : num;
}

export function getDuration(pastDate, futureDate = new Date()) {
  const { hours, mins } = getDifferenceHoursMins(pastDate, futureDate);
  return {
    hours,
    minutes: mins % 60,
  };
}

export function getDateForApi(date, onlyDate = false) {
  return onlyDate 
    ? getDateTime(date).startOf('second').toISODate() 
    : getDateTime(date).startOf('second').toISO({suppressMilliseconds: true});
}

export function getDateForReportApi(date) {
  if (!date) return null;
  if (typeof date == 'string' && date.length > 10 && !date.includes('Z')) return date;

  // Handle single-digit days and months
  if (typeof date === 'string' && date.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
    const [year, month, day] = date.split('-').map(part => part.padStart(2, '0'));
    date = `${year}-${month}-${day}`;
  }

  let fullDate = getDateTime(date).toISO({ suppressMilliseconds: true });
  const parts = getTimeDifference(fullDate);
  fullDate = `${parts.time}${parts.modifier}${parts.difference.replace(':', '')}`;
  return fullDate;
}

function getTimeDifference(date) {
  let time = '';
  let modifier = '';
  let difference = '';
  if (date) {
    const endLen = date.length - 6;
    const dateEnd = date.substr(endLen);
    time = date.substr(0, endLen);
    difference = dateEnd.substr(1);
    if (dateEnd.startsWith('+')) {
      modifier = '+';
    }
    if (dateEnd.startsWith('-')) {
      modifier = '-';
    }
  }
  return { time, modifier, difference };
}

export function getStartHours(value) {
  if (!value) return '';
  const hrs = value.getHours().toString();
  return hrs.length >= 2 ? hrs : `0${hrs}`;
}

export function getStartMinutes(value) {
  if (!value) return '';
  const mins = value.getMinutes().toString();
  return mins.length >= 2 ? mins : `0${mins}`;
}

export function getDurationHours(value) {
  const { hours = 0 } = (value || {});
  if (hours) {
    return typeof hours === 'number' ? hours : parseInt(hours, 10);
  }
  return 0;
}

export function getDurationMinutes(value) {
  const { minutes = 0 } = (value || {});
  if (minutes) {
    return typeof minutes === 'number' ? minutes : parseInt(minutes, 10);
  }
  return 0;
}

export function isTodayOrFutureDate(date) {
  return date && getDateTime(date).startOf('day') >= getDateTime(new Date()).startOf('day'); 
}

/**
 * Calculate diff between dates as an object {years, months, days, hours, ..}
 * @param {*} dateToCompare 
 * @param {*} baseDate Now by default
 * @param {*} units ['years', 'months', 'days', 'hours']
 * @returns 
 */
export function getDatesDiff(dateToCompare, baseDate: Date | string = new Date(), units = ['years', 'months', 'days', 'hours']) {
  if (!dateToCompare || !baseDate) return {};
  const baseDateTime = getDateTime(baseDate);
  const dateTimeToCompare = getDateTime(dateToCompare);
  return dateTimeToCompare.diff(baseDateTime, units).toObject();
}

export function getMonths(length = 'long', opts = { locale: 'en-gb'}) {
  return Info.months(length, opts);
}

export function getMonthStartAndEndDatesForAPI(monthNumber = DateTime.now().month, year = DateTime.now().year) {
  // Create a DateTime object for the first day of the given month and year
  const monthStart = DateTime.local(year, monthNumber, 1);
  
  // Create a DateTime object for the last day of the given month and year
  const monthEnd = monthStart.endOf('month');
  
  // Return an object containing the start and end dates
  return { 
    start: monthStart, 
    end: monthEnd 
  };
}

export default {
  format: formatDate, //
  getDateTime, //
  formatAsISO: formatDateAsISO, //
  getContractedTime, //
  getFullTime, //
  addZero, //
  getDuration, //
  forApi: getDateForApi, //
  forReportApi: getDateForReportApi, //
  getStartHours, //
  getStartMinutes, //
  getDurationHours, //
  getDurationMinutes, //
  isTodayOrFutureDate,
  getDiff: getDatesDiff, //
  getMonths, //
  getMonthStartAndEndDatesForAPI, //
};