import { getLocale } from '@/functions/translate';
import { abbrFloat } from './abbr';

export const isLeftLeaning = () => {
  const lang = getLocale().split('-')[0];
  return lang.toLowerCase() === 'en';
};

// Filters a number and adds a currency symbol based on the globally set currency name
export const currencyNameToSymbol = (number, currencyName) => {
  const cs = currencySymbol(currencyName);
  const formattedNumber = new Intl.NumberFormat(getLocale(), { minimumFractionDigits: 0 }).format(number);
  return isLeftLeaning() ? `${cs}${formattedNumber}` : `${formattedNumber}${cs}`;
};

// Shows just the currency symbol based on the globally set currency name
export const currencySymbol = currencyName => {
  switch (currencyName) {
  case 'USD':
    return '$';
  case 'GBP':
    return '£';
  case 'EUR':
    return '€';
  case 'CAD':
    return 'CA$';
  default:
    return '';
  }
};

export const cLeftFormat = (prefix, value) => isLeftLeaning() ? `${prefix}${value}` : `${value}${prefix}`;

export const cNumber = (value = 0, fractionDigits = 2) => {
  if (isNaN(value)) return value;
  if (Number.isInteger(value)) {
    fractionDigits = 0;
  }
  return value?.toLocaleString(getLocale(), {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });
};

export const cPrice = (value, prefix = '') => {
  if (isNaN(value)) return value;
  const sign = value < 0 ? '-' : '';
  const formattedValue = formatValue(Math.abs(value));

  function formatValue(value) {
    if (value >= 1000 || value <= -1000) {
      return abbrFloat(value, 2);
    } else {
      return value?.toLocaleString(getLocale(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  }

  return isLeftLeaning() ? `${sign}${prefix}${formattedValue}` : `${sign}${formattedValue}${prefix}`;
};

export default {
  isLeftLeaning,
  nameToSymbol: currencyNameToSymbol,
  symbol: currencySymbol,
  leftFormat: cLeftFormat,
  number: cNumber,
  price: cPrice
};