import { getLocale } from '@/functions/translate';

export const abbrFloat = function(num, minimumFractionDigits = 0, maximumFractionDigits = 2) {
  if (!num) return '0';
  return Intl.NumberFormat(getLocale(), {
    minimumFractionDigits,
    maximumFractionDigits,
    notation: 'compact',
    compactDisplay: 'short'
  }).format(num);
};

export const abbrInt = function (num) {
  if (!num) {
    return 0; // if value <= 0, nothing to do
  } else if (num > 999 && num < 1_000_000) {
    return Math.floor(num / 1000) + 'K'; // convert to k for number from > 1000 < 1 million
  } else if (num > 999_999 && num < 1_000_000_000) {
    return Math.floor(num / 1_000_000) + 'M'; // convert to M for number from > 1 million < 1 billion
  } else if (num > 1_000_000_000) {
    return Math.floor(num / 1_000_000_000) + 'B'; // convert to B for number from > 1 billion
  } else {
    return Math.floor(num); // if value < 1000, nothing to do
  }
};

/**
 * 1                => '8 byte'
 * 8                => '8 bytes'
 * 1024             => '1.00 kB'
 * 2000000          => '1.90 MB'
 * 2000000000       => '1.86 GB'
 * 2000000000000    => '1.82 TB'
 *
 * @param {Number} value
 * @param {Number} decimals Decimal places (default: 2)
 */

export const abbrBytes = (value, decimals) => {
  decimals = (decimals !== null && typeof decimals !== 'undefined') ? decimals : 2;
  value = value === null || isNaN(value) ? 0 : value;
  
  if (value >= Math.pow(1024, 4)) {
    // TB
    return `${(value / Math.pow(1024, 4)).toFixed(decimals)} TB`;
  } else if (value >= Math.pow(1024, 3)) {
    // GB
    return `${(value / Math.pow(1024, 3)).toFixed(decimals)} GB`;
  } else if (value >= Math.pow(1024, 2)) {
    // MB
    return `${(value / Math.pow(1024, 2)).toFixed(decimals)} MB`;
  } else if (value >= 1024) {
    // kb
    return `${(value / 1024).toFixed(decimals)} kB`;
  }
  // byte
  return value === 1 ? `${value} byte` : `${value} bytes`;
};

export default {
  float: abbrFloat,
  int: abbrInt,
  bytes: abbrBytes
};
