import { BehaviorSubject } from 'rxjs';
import { Tenant } from '@/types/tenantSubjectTypes';
import { httpRequest } from '@/functions/httpRequest';

export const tenantState = new BehaviorSubject<Tenant>({});

export async function getTenantDetails() {
  try {
    const data = await httpRequest('get', 'tenants/current', {}, {
      version: '2',
      area: 'iam',
      // sanitize: false
    });
    // change above for Sergii testing, to ENABLE sanitize for loading tenant
    tenantState.next(data);
  } catch (error) {
    console.error(error);
  }
}