import { httpRequest } from './httpRequest'; 

export const fileUpload = async (path, formData, options = {}): Promise<void> => {
  try {
    const fileUploadUrl = process.env.FILES_URL;
    // formData/payload
    return await httpRequest('post', `${fileUploadUrl}/files/v2/${path}`, formData, options);
  } catch (error) {
    console.error(error);
  }
};

export default {
  fileUpload,
};